<template>
  <div class="full-width column-center" style="background-color: #072736; padding: 50px; color: white">
    <span class="guide-title">Droits</span><span class="guide-title">作品权益声明</span>
    <img :src="icons.triangle" height="13" style="margin-top: 10px" />

    <span style="font-size: 24px; margin-top: 30px">“小莫泊桑杯”国际艺术联创</span>
    <span style="font-size: 24px">参展作品知识产权及相关声明</span>

    <p class="guide-content-width guide-18" style="margin-top: 40px">
      一. 第一届“小莫泊桑杯”国际艺术联创巡展（以下简称“联创活动”）依照《中华人民共和国著作权法》、《中华人民共和国著作权法实施条例》及其他相关法律规定开展著作权保护工作。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">
      二. 参展者一旦报名成功，并支付资料费，如因参展者个人原因不能参加联创活动的，资料费概不退还。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">
      三. 参展者须取得其监护人的同意，参展者或其监护人已知悉、确认并同意严格遵守本次联创活动征稿启事的各项规则（含参展作品知识产权及相关声明，以下简称“本声明”）。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">
      四. 参展者所提交的参展作品应由本人独立创作完成，为原创作品。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">
      五. 参展者提交的一切图文资料内容信息应真实有效，不构成对第三方知识产权、肖像权、隐私权、名誉权或者其他合法权益的侵害，否则将取消其参展资格，相关法律后果由参展者自行承担。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">
      六. 未经活动主办方同意，任何第三方不得以任何形式使用参展者及参展作品的任何信息、图像等，本约束条件不因联创活动结束而终止。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">
      七. 参展期间，参展者不得转让参展作品，不得许可第三方使用。在未取得联创活动主办方书面同意的情况下，不得将参展作品用于商业用途。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">
      八. 如因参展者的疏忽、过失、故意而发生任何侵犯版权或其他知识产权的行为，并因此给活动主办方造成任何损失和损害，参展者须负责并承担一切责任。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">
      九. 参展作品所有权归属法中交流促进会（Association pour le Développement des échanges
      France-Chinois）（以下简称“法中交流促进会”）或其指定方所有，参展作品一律不予退回。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">
      十．如果参展作品参与商业拍卖或者出售，则按照参与该参展作品的联创活动特约艺术家（Malgorzata Paszko/ Christophe Ronel/Emmanuelle Renard/Sophie
      Sainrapt/Augusto Foldi之一）3%、参与该参展作品的参展者8%的比例分享参展作品拍卖或者出售所得款项；如果参展作品参与公益拍卖，公益拍卖所得款项将全部捐赠给相关公益项目。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">
      十一.
      参展者除享有参展作品署名权之外，参展作品的其他著作权及领接权，包括但不限于参展作品发表权、修改权、保护作品完整权、复制权、发行权、展览权、表演权、放映权、信息网络传播权、摄制权、改编权、翻译权、汇编权归法中交流促进会或其指定方所有。参展作品的衍生品、基于参展作品及其衍生品生成或创作的NFT作品的完整著作权及领接权（包括但不限于署名权、发表权、修改权、保护作品完整权、复制权、发行权、展览权、表演权、放映权、信息网络传播权、摄制权、改编权、翻译权、汇编权）亦归法中交流促进会或其指定方所有。在参展作品参展期间及参展之后，法中交流促进会有权在处理参展作品相关的活动中免费使用参展者的姓名及肖像。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">十二.
      在联创活动过程中有下列侵权行为的，侵权人应当根据情况，承担停止侵害、消除影响、赔礼道歉、赔偿损失等民事责任；同时损害公共利益的，可以由著作权行政管理部门责令停止侵权行为，没收违法所得，没收、销毁侵权复制品，并可处以罚款；情节严重构成犯罪的，依法追究其法律责任：
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px; margin-left: 30px">
      1. 未经著作权人许可，将其作品用于参展的；
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px; margin-left: 30px">
      2. 未经其他作者许可将他人的作品当作自己的作品参展的；
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px; margin-left: 30px">
      3. 歪曲、篡改或剽窃他人作品的；
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px; margin-left: 30px">
      4. 其他侵犯著作权以及与著作权有关的权益的行为。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">
      十三. 如发生任何非人为因素或其他超出联创活动主办方控制能力以外的意外事件，包括地震、火灾、水灾、骚乱、战争、罢工、时疫、政府法令的变更等不可抗力事件，联创活动主办方有权取消、延期或相应调整联创活动以及有关具体安排。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">
      十四.
      参展者及其监护人在签署本声明时，已经认真阅读本声明的内容，并确认签署本声明是参展者及其监护人真实意思表示。一旦参加联创活动的任何一个环节或全部，即视为参展者及其监护人已完全理解并完全接受包括本声明在内的各项条款，并愿意承担接受本声明所产生的一切法律后果。本声明是具有法律效力的文件，本声明涉及的各方可以按照本声明确定参展者所提交的参展作品的相关法律权益。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">
      十五. 法中交流促进会或其指定方保留对联创活动的各项规则最终解释权。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px">
      十六.本电子签名系参展者法定监护人本人亲自签署，与手写签名或者签章具有同等法律效力。
    </p>
    <p class="guide-content-width guide-18" style="margin-top: 20px" bindtap="onRuleCheck">
      <a-checkbox v-model:checked="isCheck"></a-checkbox>
      本人确认：我已认真阅读并知晓《第一届“小莫泊桑杯”国际艺术联创巡展参展作品知识产权及相关声明》所述各项内容，特别对第二条、第九条、第十条中针对资料费、参展作品不予退还、作品拍卖净收益分配等相关约定已了解并同意。
    </p>
    <div class="guide-content-width guide-18 row" style="justify-content:flex-end">
      <div>签名：</div>
      <canvas id="signCanvas" ref="signCanvas" style="background: white" @mousedown="onSignMouseDown"
        @mousemove="onSignMouseMove" @mouseup="onSignMouseUp"></canvas>
    </div>
    <!-- <div class="guide-content-width row" style="justify-content:flex-end;cursor:pointer;user-select: none"
      @click="clearCanvas">
      重签
    </div> -->

    <a-button style="margin-top:30px" @click="uploadSign" :loading="signLoading">同意协议并登录</a-button>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as cos from "../../utils/cosHelper";

export default {
  data() {
    return {
      icons: this.$root.icons,

      isCheck: false,
      lineWidth: 2,
      lineColor: "#000000",
      width: 0,
      height: 0,
      // canvasElement: null,
      // canvasContext: null,
      isDraw: false,
      oldPosition: {
        x: 0,
        y: 0,
      },
      sign_url: "",
      signLoading: false,
    };
  },
  methods: {
    ...mapActions({
      userSetMySignUrlAction: "userSetMySignUrl",
    }),
    onSignMouseDown(e) {
      const { x, y } = e;
      const rect = this.$refs.signCanvas.getBoundingClientRect();
      this.oldPosition.x = x - rect.left;
      this.oldPosition.y = y - rect.top;

      this.isDraw = true;
    },
    onSignMouseMove(e) {
      if (this.isDraw) {
        const rect = this.$refs.signCanvas.getBoundingClientRect();
        const posItem = {
          x: e.x - rect.left,
          y: e.y - rect.top,
        };
        if (this.$refs.signCanvas.getContext("2d")) {
          this.draw(posItem);
        }
      }
    },
    onSignMouseUp(e) {
      this.isDraw = false;
    },
    draw(pos) {
      const context = this.$refs.signCanvas.getContext("2d");
      if (context) {
        context.lineWidth = this.lineWidth;
        context.strokeStyle = this.lineColor;

        context.beginPath();
        context.moveTo(this.oldPosition.x, this.oldPosition.y);
        context.lineTo(pos.x, pos.y);
        context.stroke();
        context.closePath();

        this.oldPosition.x = pos.x;
        this.oldPosition.y = pos.y;
      }
    },
    clearCanvas() {
      const context = this.$refs.signCanvas.getContext("2d");
      context.fillStyle = "#ffffff";
      context.fillRect(0, 0, 300, 150);
    },
    uploadSign() {
      this.signLoading = true;
      const base64 = this.$refs.signCanvas.toDataURL("image/png");
      const file = this.dataURLToFile(base64);

      // 上传
      cos
        .upload(file, "sign")
        .then((res) => {
          this.sign_url = res.fullPath;
          this.userSetMySignUrlAction({
            my_sign_url: this.sign_url,
          })
            .then((res) => {
              this.$message.success("注册成功");
              this.$router.push({
                name: "enroll",
              });
            })
            .catch((error) => {
              this.$message.error(error);
            })
            .finally(() => {
              this.signLoading = false;
            });
        })
        .catch((error) => {
          this.$message.error("上传失败");
          this.signLoading = false;
        });
    },
    dataURLToFile(dataURL, fileName, fileType) {
      let arr = dataURL.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, {
        type: fileType || "image/png",
      });
    },
  },
};
</script>

<style scoped>
.guide-content-width {
  width: 800px;
}
.guide-title {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  letter-spacing: 0;
  text-align: center;
}
.guide-18 {
  font-size: 18px;
  text-align: left;
}
</style>